export default {
  logout: 'Logout',
  home: 'Home',
  dashboard: 'Dashboard',
  menu: 'Menu',
  renameDashboard: 'Edit name',
  changeBackground: 'Change background',
  imageWidget: 'Image',
  linkButtonWidget: 'Button to URL',
  newsWidgetClassic: 'News Classic',
  reportsWidget: 'Reports',
  name: 'Name',
  email: 'E-mail',
  hello: 'Hello',
  createDashboard: 'Create Dashboard',
  enterDashboardName: 'Enter Dashboard name',
  primary: 'Primary',
  secondary: 'Secondary',
  accent: 'Accent',
  dark: 'Dark',
  positive: 'Positive',
  negative: 'Negative',
  info: 'Info',
  warning: 'Warning',
  cancel: 'Cancel',
  save: 'Save',
  saved: 'Saved',
  changeColors: 'Change colors',
  chooseFallbackDashboard: 'Fallback dashboard',
  palette: 'Palette',
  custom: 'Custom',
  color: 'Color',
  previousImages: 'Previous images',
  fileSizeExceedsMax: 'File is too big. Maximum file size is {fileSize}',
  fileTypeNotAccepted: 'File type not supported. Supported types are: {acceptedFileTypes}',
  errorDisplayingImage: 'Error displaying image. Please try another',
  image: 'Image',
  deleteImageConfirmTitle: 'Delete image?',
  deleteImageConfirmMessage: 'The image will be permanently deleted. If you would like to use it at some other time, please make sure you have a backup.',
  errorSavingDashboardName: 'An error occurred while saving the new dashboard name. Please try again later',
  pageNotFoundMessage: 'We could not find the page you are looking for',
  goBack: 'Go back',
  fireStationScreenAlarmInfoWidget: 'Fire Station Alarm Info',
  fireStationScreenParticipationWidget: 'Fire Station Participation',
  fireStationScreenDirectionsWidget: 'Fire Station Directions',
  fireStationScreenStreetViewWidget: 'Fire Station Street View',
  fireStationScreenCountdownWidget: 'Fire Station Countdown Timer',
  gc2Vidi: 'Map',
  addNewDashboard: 'New dashboard',
  dashboards: 'Dashboards',
  roles: 'Roles for startdashboard',
  deleteConfirmText: 'Are you sure you want to delete the dashboard:',
  confirmDelete: 'Delete',
  toDeleteTypeTheName: 'To delete type the name of the dashboard',
  notSameText: 'The text is not the same',
  noDashboardFoundForDefault: 'No dashboard found without roles',
  fireStationDashboardScreenSwitcherWidget: 'Fire Station Dashboard Switcher',
  backgroundSize: {
    label: 'Size',
    auto: 'Auto',
    cover: 'Cover',
    contain: 'Contain'
  },
  backgroundPosition: {
    label: 'Position',
    leftTop: 'Left Top',
    leftCenter: 'Left Center',
    leftBottom: 'Left Bottom',
    rightTop: 'Right Top',
    rightCenter: 'Right Center',
    rightBottom: 'Right Bottom',
    centerTop: 'Center Top',
    centerCenter: 'Center Center',
    centerBottom: 'Center Bottom'
  },
  backgroundRepeat: {
    label: 'Repeat',
    noRepeat: 'No',
    repeat: 'With clipping',
    repeatX: 'Horizontal with clipping',
    repeatY: 'Vertical with clipping',
    space: 'With spacing',
    spaceX: 'Horizontal with spacing',
    spaceY: 'Vertical with spacing',
    round: 'With stretching',
    roundX: 'Horizontal with stretching',
    roundY: 'Vertical with stretching'
  },
  hideBackground: 'Hide background',
  dashboardNavigationButtonWidget: 'Dashboard Navigation Button',
  dashboardHomeButtonWidget: 'Dashboard Home Button',
  fireStationScreenLatestAlarmsWidget: 'Fire Station Latest Alarms',
  layoutTypes: {
    xl: 'TV, extra large screen',
    lg: 'Desktop, large screen',
    md: 'Laptop, small screen',
    sm: 'iPad, tablet',
    xs: 'iPhone, mobile device'
  },
  layout: 'Layout',
  required: 'This field is required',
  rssFeed: 'RSS',
  mainIncidentListWidget: 'Incident Reporting Main View',
  incidentDetailsWidget: 'Incident Reporting Details',
  dashboardName: 'Dashboard name',
  noResult: 'No results',
  dashboardNotFound: 'Dashboard not found',
  fallbackDashboardNotFound: 'The dashboard set up for you has been deleted. Contact administrator',
  unableToSetUserDashboard: 'There is no dashboard set up for you. Contact administrator',
  videoPlayerWidget: 'Video Player',
  genericEventLogWidget: 'Generic Event Log',
  cookieConsentMessage: 'By using RMG C3 we assume that you accept our use of necessary cookies.',
  linkButtonCustomPageWidget: 'Button to Custom Page',
  linkButtonEventActivationWidget: 'Button for Event Activation',
  linkButtonStatusToggleButton: 'Button for Status Toggle',
  fireStationScreenCountupWidget: 'Fire Station Screen Countup Timer',
  roleUsedForOtherDashboard: 'The role \'{role}\' is already in the dashboard \'{dashboard}\'',
  formBuilderWidget: 'Form Builder',
  formFillerWidget: 'Form Filler',
  addWidget: 'Add widget',
  formListWidget: 'Form List',
  documentManagementWidget: 'Document Management',
  copyRightNotice: '© {Year} RM-Group A/S',
  infoTextWidget: 'Info Text',
  dismiss: 'Dismiss',
  offlineAccessTokenWarningTitle: 'Security notice',
  offlineAccessTokenWarningMessage: 'You have logged on with a profile that will stay logged on for you convenience. If you suspect that this machine is compromised is any way then immediately contact RM Group A/S.',
  newsCarouselWidgetClassic: 'News carousel Classic',
  googleDataStudioWidget: 'Google Data Studio',
  documentLinkWidget: 'Document Link',
  functionDisplayWidget: 'Function Display',
  vehicleErrorReportingWidget: 'Error Report',
  vehicleErrorReportingAdminWidget: 'Error Report Admin',
  dashboardSwitcherTimedWidget: 'Timed Dashboard Switcher',
  alertWidget: 'Alert Button',
  alertListWidget: 'Alert List',
  alarmDisarmPermissionLookupWidget: 'Alarm Disarm Permissions',
  alarmDisarmPermissionAdminWidget: 'Alarm Disarm Permissions - Admin',
  toDeleteTypeTheNameOfGroup: 'To delete type the name of the group',
  addNewGroup: 'New group',
  group: 'Group',
  fireSafetyControlOperationManagerWidget: 'DKV-Plans (BR18) Operation Manager',
  fireSafetyControlAdminWidget: 'DKV-Plans (BR18) Administration',
  fireSafetyControlWidget: 'DKV-Plans (BR18) Area Responsible',
  fireSafetyControlDocumentationControlWidget: 'DKV-Planer (BR18) Dokumentation for Control',
  bookingCalendarWidget: 'Calendar',
  documentFolderButtonWidget: 'Document Folder Link',
  newsWidget: 'News',
  alarmDisarmPermissionPersonAdminWidget: 'Alarm Disarm Permissions Admin - Person',
  serviceTasksWidget: 'Service Tasks',
  ok: 'OK',
  newContentMessage: 'A new version is available. We\'ll update automatically in { count } second. When updating, changes you have not saved will be lost. Press the Later button to postpone the update. | A new version is available. We\'ll update automatically in { count } seconds. When updating, changes you have not saved will be lost. Press the Later button to postpone the update.A new version is available. We\'ll update automatically in { count } seconds. When updating, changes you have not saved will be lost.Press the Later button to postpone the update.',
  updateNow: 'Now',
  updateLater: 'Later',
  noRealmFound: 'The system \'{realm}\' was not found. Try again.',
  useNewRealmTitle: 'Change system in the app?',
  useNewRealmMessage: 'When you installed the app, we detected a different system than the one you\'re currently logged into. Do you want to switch the app to use the new system?',
  noExistingLayout: 'It could not create a new layout since no existing layout was found.',
  realmName: 'System name or URL',
  inputRealm: 'Enter the name or URL of the system you want to use',
  conferenceCallWidget: 'Conference Call',
  crisisManagementMainListWidget: 'Crisis Management Main View',
  crisisManagementDetailsWidget: 'Crisis Management Details',
  crisisManagementStartButtonWidget: 'Crisis Management Start Button',
  crisisManagementCreateCommentButtonWidget: 'Crisis Management Create Comment Button',
  add: 'Add',
  alarmInfoVehicleWidget: 'Fire Truck Alarm Info',
  alarmInfoLogsWidget: 'Fire Truck Alarm Logs',
  alarmsWidget: 'Alarm Acknowledgment List',
  crisisManagementChecklistWidget: 'Crisis Management Checklist',
  crisisManagementChecklistAdminWidget: 'Crisis Management Checklist Administration',
  taskforceOverviewWidget: 'Taskforce Overview',
  mobilizeAvailabilityToggleWidget: 'Mobilize Availability Toggle',
  mobilizeAvailabilityListWidget: 'Mobilize Availability List',
  mobilizeStartButtonWidget: 'Mobilize Start Button',
  mobilizeParticipantSelectionList: 'Mobilize Paticipant Selection List',
  mobilizeOngoingIncidentList: 'Mobilize Ongoing Incident List',
  mobilizeNavigationButton: 'Mobilize Navigation Button',
  spacerWidget: 'Spacer',
  confirm: 'Confirm',
  confirmDeletion: 'Are you sure you want to delete?',
  heightIndicatorTooltip: 'Indicates the approximate height of the selected layout.\r\nDepending on device type and setup final results may vary!',
  layoutTooltip: {
    xl: 'Matches most large screens like for example 4K TVs.\r\nThe dashed border around the screen is only a guideline,\r\nshowing the approximate boundaries of the device!\r\nDepending on device type and setup final results may vary!',
    lg: 'Matches most desktop computers with high resolution displays.\r\nThe dashed border around the screen is only a guideline,\r\nshowing the approximate boundaries of the device!\r\nDepending on device type and setup final results may vary!',
    md: 'Matches most laptops with medium resolution displays.\r\nThe dashed border around the screen is only a guideline,\r\nshowing the approximate boundaries of the device!\r\nDepending on device type and setup final results may vary!',
    sm: 'Matches a 10th generation iPad well, but likely also other tablets.\r\nThe dashed border around the screen is only a guideline,\r\nshowing the approximate boundaries of the device!\r\nDepending on device type and setup final results may vary!',
    xs: 'Matches an iPhone 14 well, but likely also other mobile phones.\r\nThe dashed border around the screen is only a guideline,\r\nshowing the approximate boundaries of the device!\r\nDepending on device type and setup final results may vary!'
  },
  deleted: 'Deleted',
  errorDeletingDashboard: 'An error occurred while deleting the dashboard. Please try again later',
  tooManyReloadsDuringLogin: 'The page was reloaded too many times during login. Try closing all browser windows and try again',
  accessControl: 'Access control',
  rolesAccess: 'Roles for access to dashboard',
  accessDenied: 'You dont have access to this dashboard',
  statusButtonWidget: 'Status Button',
  worldClockWidget: 'World Clock',
  activityLogWidget: 'Activity Log List',
  activityLogAdministrationTypesWidget: 'Aktivitetslog Type Administration',
  activityLogAdministrationOverallStructureWidget: 'Aktivitetslog Overall Structur Administration',
  activityLogAdministrationSubordinateStructureWidget: 'Aktivitetslog Subordinate Structur Administration',
  activityLogAdministrationSubscriptionWidget: 'Activity Log Subscription Administration',
  activityLogAdministrationTaskAutomationWidget: 'Activity Log Automated Task Management',
  activityLogButtonWidget: 'Activity Log Button',
  riskStatusVisualizerWidget: 'Form barometer',
  mobilizeListWidget: 'Mobilize List',
  mobilizeParticipateButtonWidget: 'Mobilize Participate Button',
  watchPlanStationWidget: 'Guard Station Admin',
  watchPlanAdminWidget: 'Guard List',
  mobilizePlayVoiceMessageButtonWidget: 'Mobilize Play Voice Message Button',
  activityLogRiskStatusVisualizerWidget: 'Activity Log barometer',
  crisisManagementRiskStatusVisualizerWidget: 'Crisis Management barometer',
  bridgeAnnouncementsSendMessageButtonWidget: 'Bridge Announcements Send Message Button',
  bridgeAnnouncementsWidget: 'Bridge Announcements List',
  userAdministrationWidget: 'User Administration'
}
