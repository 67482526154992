export default {
  logout: 'Log ud',
  home: 'Hjem',
  dashboard: 'Dashboard',
  menu: 'Menu',
  renameDashboard: 'Redigér navn',
  changeBackground: 'Skift baggrund',
  imageWidget: 'Billede',
  linkButtonWidget: 'Knap til URL',
  newsWidgetClassic: 'Nyheder Classic',
  reportsWidget: 'Meldinger',
  name: 'Navn',
  email: 'E-mail',
  hello: 'Hej',
  createDashboard: 'Opret Dashboard',
  enterDashboardName: 'Indtast Dashboardnavn',
  primary: 'Primær',
  secondary: 'Sekundær',
  accent: 'Accent',
  dark: 'Mørk',
  positive: 'Positiv',
  negative: 'Negativ',
  info: 'Info',
  warning: 'Advarsel',
  cancel: 'Annuller',
  save: 'Gem',
  saved: 'Gemt',
  changeColors: 'Skift farver',
  chooseFallbackDashboard: 'Standard dashboard',
  palette: 'Palet',
  custom: 'Brugerdefineret',
  color: 'Farve',
  previousImages: 'Tidligere billeder',
  fileSizeExceedsMax: 'Filen er for stor. Maksimal filstørrelse er {fileSize}',
  fileTypeNotAccepted: 'Filtypen understøttes ikke. Understøttede typer er: {acceptedFileTypes}',
  errorDisplayingImage: 'Fejl ved visning af billede. Prøv et andet',
  image: 'Billede',
  deleteImageConfirmTitle: 'Slet billede?',
  deleteImageConfirmMessage: 'Billedet slettes permanent. Hvis du vil bruge det en anden gang, skal du sørge for at have en sikkerhedskopi.',
  errorSavingDashboardName: 'Der opstod en fejl under lagring af det nye dashboardnavn. Prøv igen senere',
  pageNotFoundMessage: 'Vi kunne ikke finde den side du leder efter',
  goBack: 'Gå tilbage',
  fireStationScreenAlarmInfoWidget: 'Brandstation Alarm-info',
  fireStationScreenParticipationWidget: 'Brandstation Deltagelse',
  fireStationScreenDirectionsWidget: 'Brandstation Kørselsvejledning',
  fireStationScreenStreetViewWidget: 'Brandstation Street View',
  fireStationScreenCountdownWidget: 'Brandstation Nedtællingsur',
  addNewDashboard: 'Nyt dashboard',
  dashboards: 'Dashboards',
  roles: 'Roller for startdashboard',
  deleteConfirmText: 'Bekræft sletning af',
  confirmDelete: 'Slet',
  toDeleteTypeTheName: 'For at slette skriv navnet på dashboardet',
  notSameText: 'Teksten er ikke ens',
  noDashboardFoundForDefault: 'Der blev ikke fundet noget dashboard uden roller',
  fireStationDashboardScreenSwitcherWidget: 'Brandstation Dashboardskifter',
  gc2Vidi: 'Kort',
  backgroundSize: {
    label: 'Størrelse',
    auto: 'Auto',
    cover: 'Dæk',
    contain: 'Indehold'
  },
  backgroundPosition: {
    label: 'Placering',
    leftTop: 'Venstre Top',
    leftCenter: 'Venstre Midte',
    leftBottom: 'Venstre Bund',
    rightTop: 'Højre Top',
    rightCenter: 'Højre Midte',
    rightBottom: 'Højre Bund',
    centerTop: 'Midte Top',
    centerCenter: 'Midte Midte',
    centerBottom: 'Midte Bund'
  },
  backgroundRepeat: {
    label: 'Gentag',
    noRepeat: 'Nej',
    repeat: 'Med klipning',
    repeatX: 'Vandret med klipning',
    repeatY: 'Lodret med klipning',
    space: 'Med afstand',
    spaceX: 'Vandret med afstand',
    spaceY: 'Lodret med afstand',
    round: 'Med strækning',
    roundX: 'Vandret med strækning',
    roundY: 'Lodret med strækning'
  },
  hideBackground: 'Skjul baggrund',
  dashboardNavigationButtonWidget: 'Dashboard-navigeringsknap',
  dashboardHomeButtonWidget: 'Dashboard-startknap',
  fireStationScreenLatestAlarmsWidget: 'Brandstation Seneste Alarmer',
  layoutTypes: {
    xl: 'TV, ekstra stor skærm',
    lg: 'Desktop, stor skærm',
    md: 'Laptop, lille skærm',
    sm: 'iPad, tablet',
    xs: 'iPhone, mobilenhed'
  },
  layout: 'Layout',
  required: 'Dette felt er påkrævet',
  rssFeed: 'RSS',
  mainIncidentListWidget: 'Hændelsesrapportering Hovedvisning',
  incidentDetailsWidget: 'Hændelsesrapportering Detaljer',
  dashboardName: 'Dashboardnavn',
  noResult: 'Ingen resultater',
  dashboardNotFound: 'Dashboard ikke fundet',
  fallbackDashboardNotFound: 'Det dashboard, der er opsat til dig, er blevet slettet. Kontakt administrator',
  unableToSetUserDashboard: 'Der er ikke opsat et dashboard til dig. Kontakt administrator',
  videoPlayerWidget: 'Videoafspiller',
  genericEventLogWidget: 'Generisk Hændelseslog',
  cookieConsentMessage: 'Ved brug af RMG C3 kræves nødvendige cookies, fortsat brug betragtes som accept af dette.',
  linkButtonCustomPageWidget: 'Knap til Specialside',
  linkButtonEventActivationWidget: 'Knap til Hændelsesaktivering',
  linkButtonStatusToggleButton: 'Knap til Statusskift',
  fireStationScreenCountupWidget: 'Brandstation Optællingsur',
  roleUsedForOtherDashboard: 'Rollen \'{role}\' bruges allerede i dashboardet \'{dashboard}\'',
  formBuilderWidget: 'Formularkonstruktion',
  formFillerWidget: 'Formularudfyldning',
  addWidget: 'Tilføj widget',
  formListWidget: 'Formular Liste',
  documentManagementWidget: 'Dokument Håndtering',
  copyRightNotice: '© {currentYear} RM-Group A/S',
  infoTextWidget: 'Info Tekst',
  dismiss: 'Skjul',
  offlineAccessTokenWarningTitle: 'Sikkerhedsmeddelelse',
  offlineAccessTokenWarningMessage: 'Du er logget på med en profil, der vil forblive logget på for nemheds skyld. Hvis du har mistanke om, at denne maskine muligvis er kompromitteret, skal du straks kontakte RM Group A/S.',
  newsCarouselWidgetClassic: 'Nyhedskarrusel Classic',
  googleDataStudioWidget: 'Google Data Studio',
  documentLinkWidget: 'Dokumentlink',
  functionDisplayWidget: 'Funktionsvisning',
  vehicleErrorReportingWidget: 'Fejlmeldinger',
  vehicleErrorReportingAdminWidget: 'Fejlmeldinger-Administration',
  dashboardSwitcherTimedWidget: 'Tidsbestemt Dashboardskifter',
  alertWidget: 'Varslingsknap',
  alertListWidget: 'Varslingsliste',
  alarmDisarmPermissionLookupWidget: 'Alarmfrakoblingstilladelser',
  alarmDisarmPermissionAdminWidget: 'Alarmfrakoblingstilladelser Admin',
  toDeleteTypeTheNameOfGroup: 'For at slette skriv navnet på group',
  addNewGroup: 'Ny gruppe',
  group: 'Gruppe',
  fireSafetyControlOperationManagerWidget: 'DKV-Planer (BR18) Driftansvarlig',
  fireSafetyControlAdminWidget: 'DKV-Planer (BR18) Administration',
  fireSafetyControlWidget: 'DKV-Planer (BR18) Områdeansvarlig',
  fireSafetyControlDocumentationControlWidget: 'DKV-Planer (BR18) Dokumentation Til Kontrol',
  bookingCalendarWidget: 'Kalender',
  documentFolderButtonWidget: 'Dokumentmappelink',
  newsWidget: 'Nyheder',
  alarmDisarmPermissionPersonAdminWidget: 'Alarmfrakoblingstilladelser Admin - Person',
  serviceTasksWidget: 'Serviceopgaver',
  ok: 'OK',
  newContentMessage: 'Der er en ny version tilgængelig. Vi opdaterer automatisk om {count} sekund. Ved opdatering vil ændringer, du ikke har gemt, gå tabt. Tryk på Senere-knappen for at udskyde opdateringen. | Der er en ny version tilgængelig. Vi opdaterer automatisk om {count} sekunder. Ved opdatering vil ændringer, du ikke har gemt, gå tabt. Tryk på Senere-knappen for at udskyde opdateringen.',
  updateNow: 'Nu',
  updateLater: 'Senere',
  noRealmFound: 'Systemet \'{realm}\' blev ikke fundet. Prøv igen.',
  useNewRealmTitle: 'Skift system i appen?',
  useNewRealmMessage: 'Da du installerede appen, registrerede vi et andet system end det, du i øjeblikket er logget ind på. Vil du skifte appen om til at bruge det nye system?',
  noExistingLayout: 'Der kunne ikke laves et nyt layout, da der ikke kunne findes et eksisterende layout.',
  realmName: 'Systemnavn eller URL',
  inputRealm: 'Indtast navn eller URL på det system du vil benytte',
  conferenceCallWidget: 'Konferencekald',
  crisisManagementMainListWidget: 'Krisestyring Hovedvisning',
  crisisManagementDetailsWidget: 'Krisestyring Detaljer',
  crisisManagementStartButtonWidget: 'Krisestyrings start knap',
  crisisManagementCreateCommentButtonWidget: 'Krisestyring opret kommentar Knap',
  add: 'Tilføj',
  alarmInfoVehicleWidget: 'Brandbil Alarm-info',
  alarmInfoLogsWidget: 'Brandbil Alarm-logbeskeder',
  alarmsWidget: 'Alarm kvitteringsliste',
  crisisManagementChecklistWidget: 'Krisestyring Checkliste',
  crisisManagementChecklistAdminWidget: 'Krisestyring Checkliste Administration',
  taskforceOverviewWidget: 'Styrkeoversigt',
  mobilizeAvailabilityToggleWidget: 'Mobilisér Tilgængelighedsskifter',
  mobilizeAvailabilityListWidget: 'Mobilisér Tilgængelighedsliste',
  mobilizeStartButtonWidget: 'Mobilisér Startknap',
  mobilizeParticipantSelectionList: 'Mobilisér Deltagerudvælgelsesliste',
  mobilizeOngoingIncidentList: 'Mobilisér Igangværende Hændelsesliste',
  mobilizeNavigationButton: 'Mobilisér Navigeringsknap',
  spacerWidget: 'Afstandsstykke',
  confirm: 'Bekræft',
  confirmDeletion: 'Er du sikker på, at du vil slette?',
  heightIndicatorTooltip: 'Angiver den omtrentlige højde af det valgte layout.\r\nAfhængig af enhedstype og opsætning kan det endelige resultat variere!',
  layoutTooltip: {
    xl: 'Passer til de fleste store skærme, som f.eks. 4K TV.\r\nDen stiplede kant omkring skærmen er kun en vejledning,\r\nder viser de omtrentlige grænser for enheden!\r\nAfhængig af enhedstype og opsætning kan det endelige resultat variere!',
    lg: 'Passer til de fleste stationære computere med skærme i høj opløsning.\r\nDen stiplede kant omkring skærmen er kun en vejledning,\r\nder viser de omtrentlige grænser for enheden!\r\nAfhængig af enhedstype og opsætning kan det endelige resultat variere!',
    md: 'Passer til de fleste bærbare computere med skærme i mellemopløsning.\r\nDen stiplede kant omkring skærmen er kun en vejledning,\r\nder viser de omtrentlige grænser for enheden!\r\nAfhængig af enhedstype og opsætning kan det endelige resultat variere!',
    sm: 'Passer godt til en 10. generations iPad, men sikkert også til andre tablets.\r\nDen stiplede kant omkring skærmen er kun en vejledning,\r\nder viser de omtrentlige grænser for enheden!\r\nAfhængig af enhedstype og opsætning kan det endelige resultat variere!',
    xs: 'Passer godt til en iPhone 14, men sikkert også til andre mobiltelefoner.\r\nDen stiplede kant omkring skærmen er kun en vejledning,\r\nder viser de omtrentlige grænser for enheden!\r\nAfhængig af enhedstype og opsætning kan det endelige resultat variere!'
  },
  deleted: 'Slettet',
  errorDeletingDashboard: 'Der opstod en fejl under sletning af dashboard. Prøv igen senere',
  tooManyReloadsDuringLogin: 'Siden blev genindlæst for mange gange under login. Prøv at lukke alle browservinduer og prøv igen',
  accessControl: 'Adgangskontrol',
  rolesAccess: 'Roller for adgang til dashboard',
  accessDenied: 'Du har ikke adgang til dette dashboard',
  statusButtonWidget: 'Status Knap',
  worldClockWidget: 'Verdensur',
  activityLogWidget: 'Aktivitetslogliste',
  activityLogAdministrationTypesWidget: 'Aktivitetslog Type Administration',
  activityLogAdministrationOverallStructureWidget: 'Aktivitetslog Overordnet Struktur Administration',
  activityLogAdministrationSubordinateStructureWidget: 'Aktivitetslog Underordnet Struktur Administration',
  activityLogAdministrationSubscriptionWidget: 'Aktivitetslog Abonnement Administration',
  activityLogAdministrationTaskAutomationWidget: 'Aktivitetslog Automatisk Opgavestyring',
  activityLogButtonWidget: 'Aktivitetslog Knap',
  riskStatusVisualizerWidget: 'Formular barometer',
  mobilizeListWidget: 'Mobilisér Liste',
  mobilizeParticipateButtonWidget: 'Mobilisér Deltage Knap',
  watchPlanStationWidget: 'Vagt Stationsadmin',
  watchPlanAdminWidget: 'Vagt Liste',
  mobilizePlayVoiceMessageButtonWidget: 'Mobilisér Afspil Lydbesked Knap',
  activityLogRiskStatusVisualizerWidget: 'Aktivitetslog barometer',
  crisisManagementRiskStatusVisualizerWidget: 'Krisestyring barometer',
  bridgeAnnouncementsSendMessageButtonWidget: 'Bro Melding Send Besked Knap',
  bridgeAnnouncementsWidget: 'Bro Melding Liste',
  userAdministrationWidget: 'Bruger Administration'
}
